let id = 1;
const randomId = () => {
	id++;
	return id;
};

export const navbarItems = [
	{
		id: randomId(),
		link: "/",
		icon: "fas fa-home",
		title: "لوحة التحكم",
		children: [],
	},
	{
		id: randomId(),
		link: "/moderators/index",
		icon: "fas fa-user",
		title: "المشرفين",
		children: [
			{
				id: randomId(),
				title: "جميع المشرفين",
				link: "/moderators/index",
			},
			{
				id: randomId(),
				title: "اضافة مشرف جديد",
				link: "/moderators/create",
			},
		],
	},
	// {
	// 	id: randomId(),
	// 	link: "/blog/index",
	// 	icon: "fas fa-edit",
	// 	title: "المدونة",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "جميع المقالات",
	// 			link: "/blog/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "اضافة مقال جديد",
	// 			link: "/blog/create",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "التصنيفات",
	// 			link: "/categories/index",
	// 		},
	// 	],
	// },
	// {
	// 	id: randomId(),
	// 	link: "/services/index",
	// 	icon: "fas fa-layer-group",
	// 	title: "الخدمات",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "جميع الخدمات",
	// 			link: "/services/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "اضافة خدمة جديدة",
	// 			link: "/services/create",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "طلبات الخدمات",
	// 			link: "/services/applications",
	// 		},
	// 	],
	// },

	{
		id: randomId(),
		link: "/partners/index",
		icon: "fas fa-handshake",
		title: "الشركاء",
		children: [
			{
				id: randomId(),
				title: "جميع الشركاء",
				link: "/partners/index",
			},
			{
				id: randomId(),
				title: "اضافة شريك جديدة",
				link: "/partners/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/contact/index",
		icon: "fas fa-envelope",
		title: "اتصل بنا",
		children: [],
	},
	{
		id: randomId(),
		link: "/mainVideo/index",
		icon: "fas fa-envelope",
		title: "الفيديو",
		children: [],
	},
	{
		id: randomId(),
		link: "/footerLinks/index",
		icon: "fas fa-link",
		title: "روابط الفوتر",
		children: [],
	},
	{
		id: randomId(),
		link: "/spliters/index",
		icon: "fas fa-link",
		title: " الفواصل",
		children: [],
	},

	{
		id: randomId(),
		link: "/benefits/benefitsHeader/index",
		icon: "fas fa-id-card",
		title: "قسم الفوائد",
		children: [
			{
				id: randomId(),
				title: " العنوان",
				link: "/benefits/benefitsHeader/index",
			},
			{
				id: randomId(),
				title: "الفيديوهات",
				link: "/benefits/benefitsVideo/index",
			},
		],
	},
	{
		id: randomId(),
		link: "/slider/index",
		icon: "far fa-images",
		title: "سلايدر",
		children: [
			{
				id: randomId(),
				title: "جميع السلايدات",
				link: "/slider/index",
			},
			{
				id: randomId(),
				title: "اضافة سلايد جديد",
				link: "/slider/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/gallery/index",
		icon: "far fa-images",
		title: "المعرض",
		children: [
			{
				id: randomId(),
				title: "جميع صور المعرض",
				link: "/gallery/index",
			},
			{
				id: randomId(),
				title: "اضافة صوره جديد",
				link: "/gallery/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/banners/index",
		icon: "far fa-images",
		title: "البانر",
		children: [
			{
				id: randomId(),
				title: "جميع صور البانر",
				link: "/banners/index",
			},
			{
				id: randomId(),
				title: "اضافة صوره جديد",
				link: "/banners/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/works/index",
		icon: "fas fa-images",
		title: "صفحه أعمالنا ",
		children: [
			{
				id: randomId(),
				title: "جميع الأعمال",
				link: "/works/index",
			},
			{
				id: randomId(),
				title: "اضافة عمل جديد",
				link: "/works/create",
			},
			{
				id: randomId(),
				title: "جميع الفيديوهات ",
				link: "/works/worksvideos/index",
			},
			{
				id: randomId(),
				title: "السيو ",
				link: "/works/ceo",
			},
		],
	},
	{
		id: randomId(),
		link: "/centers/index",
		icon: "fas fa-car",
		title: "صفحه المراكز المعتمدة ",
		children: [
			{
				id: randomId(),
				title: "المراكز",
				link: "/centers/index",
			},
			{
				id: randomId(),
				title: "السيو",
				link: "/centers/ceo",
			},
		],
	},
	{
		id: randomId(),
		link: "/aboutPage",
		icon: "far fa-images",
		title: "صفحه من نحن",
		children: [
			{
				id: randomId(),
				title: "البيانات لاساسيه",
				link: "/aboutPage/header/index",
			},
			{
				id: randomId(),
				title: "البيانات الاضافيه",
				link: "/aboutPage/information/show",
			},
			{
				id: randomId(),
				title: "المميزات",
				link: "/aboutPage/advantages/index",
			},
			{
				id: randomId(),
				title: "الاحصائيات",
				link: "/aboutPage/statistics/index",
			},
			{
				id: randomId(),
				title: "الأسئله الشائعه",
				link: "/aboutPage/questions/index",
			},
		],
	},
	{
		id: randomId(),
		link: "/healthPage",
		icon: "far fa-images",
		title: "صفحه عالم الصحه",
		children: [
			{
				id: randomId(),
				title: "السكشن الاول",
				link: "/healthPage/HealthSectionOne/show",
			},
			{
				id: randomId(),
				title: "السكشن الثاني",
				link: "/healthPage/HealthSectionTwo/show",
			},
			{
				id: randomId(),
				title: "السكشن الثالث",
				link: "/healthPage/HealthSectionThree/show",
			},
			{
				id: randomId(),
				title: "السكشن الرابع",
				link: "/healthPage/HealthSectionFour/show",
			},
			{
				id: randomId(),
				title: "السكشن الخامس",
				link: "/healthPage/HealthSectionFive/show",
			},
			{
				id: randomId(),
				title: "السكشن السادس",
				link: "/healthPage/HealthSectionSix/show",
			},
			{
				id: randomId(),
				title: "السكشن السابع",
				link: "/healthPage/HealthSectionSeven/show",
			},
			{
				id: randomId(),
				title: "السكشن الثامن",
				link: "/healthPage/HealthSectionEight/show",
			},
			{
				id: randomId(),
				title: "السكشن التاسع",
				link: "/healthPage/HealthSectionNine/show",
			},
			{
				id: randomId(),
				title: "السكشن العاشر",
				link: "/healthPage/HealthSectionTen/show",
			},
			{
				id: randomId(),
				title: "السيو",
				link: "/healthPage/ceo",
			},
		],
	},
	// {
	// 	id: randomId(),
	// 	link: "/automotivePage",
	// 	icon: "far fa-images",
	// 	title: "صفحه المنتجات",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "البيانات لاساسيه",
	// 			link: "/automotivePage/automotivePosts/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "الفيديو",
	// 			link: "/automotivePage/automotiveVideo/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "السكاشن",
	// 			link: "/automotivePage/automotiveSections/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "العناوين",
	// 			link: "/automotivePage/automotiveHeaders/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "الفواصل",
	// 			link: "/automotivePage/automotiveSpliters/index",
	// 		},
	// 	],
	// },
	{
		id: randomId(),
		link: "/productOnePage",
		icon: "far fa-images",
		title: " صفحه عالم السيارات",
		children: [
			{
				id: randomId(),
				title: "البيانات لاساسيه",
				link: "/productOnePage/ProductOneHeader/index",
			},
			{
				id: randomId(),
				title: "المنتجات ",
				link: "/productOnePage/ProductOnePosts/index",
			},
			// {
			// 	id: randomId(),
			// 	title: "البوستات الجزء الثاني",
			// 	link: "/productOnePage/ProductOnePostsTwo/index",
			// },
			{
				id: randomId(),
				title: "الملف",
				link: "/productOnePage/ProductOneFile/show",
			},
			{
				id: randomId(),
				title: "السيو",
				link: "/productOnePage/ceo",
			},
		],
	},
	{
		id: randomId(),
		link: "/productTwoPage",
		icon: "far fa-images",
		title: " صفحه عالم المنزل",
		children: [
			{
				id: randomId(),
				title: "البيانات لاساسيه",
				link: "/productTwoPage/ProductTwoHeader/index",
			},
			{
				id: randomId(),
				title: "المنتجات",
				link: "/productTwoPage/ProductTwoPosts/index",
			},
			{
				id: randomId(),
				title: "الملف",
				link: "/productTwoPage/ProductTwoFile/show",
			},
			{
				id: randomId(),
				title: "السيو",
				link: "/productTwoPage/ceo",
			},
		],
	},
	// {
	// 	id: randomId(),
	// 	link: "/industryPage",
	// 	icon: "far fa-images",
	// 	title: "صفحه الصناعه",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "البيانات لاساسيه",
	// 			link: "/industryPage/posts/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "الفيديو",
	// 			link: "/industryPage/video/index",
	// 		},
	// 	],
	// },
	{
		id: randomId(),
		link: "/ceo",
		icon: "far fa-link",
		title: "سيو الصفحه الرئيسيه",
		children: [],
		// children: [
		// 	{
		// 		id: randomId(),
		// 		title: "facebok analytics",
		// 		link: "/ceo",
		// 	},
		// ],
	},
	// {
	// 	id: randomId(),
	// 	link: "/newsletter/index",
	// 	icon: "far fa-envelope",
	// 	title: "القائمة البريدية",
	// 	children: [],
	// },
];
