import NavItem from "./NavItem";
import { navbarItems } from "../../helpers/data/navbar";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/logo-colored.png";
import settings from "../../assets/images/icons/settings.svg";
import logoutIcon from "../../assets/images/icons/logout.svg";
import { Link, useHistory } from "react-router-dom";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { logout } from "../../store/actions/userActions";

const Sidebar = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const toggleResponsiveMenu = () => {
		const sidebar = document.getElementById("sidebar");
		sidebar.classList.toggle("sidebar-responsive");
		const sidebarWrapper = document.getElementById("sm-wrapper");
		sidebarWrapper.classList.toggle("sm-wrapper-toggle");
	};

	const handleLogout = e => {
		e.preventDefault();
		dispatch(logout());
		cookie.remove("token");
		history.push("/login");
		toast.success("تم تسجيل خروجك بنجاح!");
	};

	return (
		<>
			<section className="sidebar" id="sidebar">
				<a
					href="https://hexa4nano.com/"
					target="_blank"
					rel="noreferrer"
					className="logo"
				>
					<img src={logo} alt="Ejjadh-logo" />
				</a>
				<ul className="sidebar-menu list-inline text-center">
					<li className="list-inline-item">
						<Link to="/settings">
							<img src={settings} alt="settings" />
						</Link>
					</li>
					<li className="list-inline-item">
						<a onClick={handleLogout}>
							<img src={logoutIcon} alt="logout" />
						</a>
					</li>
				</ul>
				<div className="accordion" id="sidebar-menu">
					{navbarItems.map(item => (
						<NavItem item={item} key={item.id} />
					))}
				</div>
			</section>
			<section
				className="sm-wrapper d-block d-sm-none"
				id="sm-wrapper"
				onClick={toggleResponsiveMenu}
			></section>
		</>
	);
};

export default Sidebar;
