import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateMeta } from "../store/actions/metaAction";
import { Link } from "react-router-dom";

const Home = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "الرئيسية",
				breadcrumb: "الرئيسية",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	return (
		<section className="homePage">
			<p>مرحبا بك في لوحة التحكم الخاصة بك</p>
			<ul className="list-inline">
				<li className="list-inline-item">
					<Link to="/works/index">صفحة أعمالنا</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/centers/index">صفحة المراكز المعتمده</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/aboutPage/header/index">صفحة من نحن</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/productOnePage/ProductOneHeader/index">
						صفحة عالم السيارات
					</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/productTwoPage/ProductTwoHeader/index">
						صفحة عالم المنزل
					</Link>
				</li>
				<li className="list-inline-item">
					<Link to="/settings">اعدادات الموقع</Link>
				</li>
			</ul>
		</section>
	);
};

export default Home;
