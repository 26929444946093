import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";
import { useHistory } from "react-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const ProductOneFileShow = ({ match }) => {
	const {
		params: { informationId },
	} = match;

	const auth = useSelector(state => state.auth.user);

	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [loading, setLoading] = useState(true);
	const [file, setFile] = useState(userPlaceholder);

	const dispatch = useDispatch();
	const history = useHistory();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل البيانات",
				breadcrumb: "البيانات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			// .get(`${process.env.REACT_APP_API_URL}/posts/${informationId}`, {
			.get(`${process.env.REACT_APP_API_URL}/posts/product_one_file/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setTitle(response.data.data[0].categories[0].posts[0].data.title.ar);
				setTitleEn(response.data.data[0].categories[0].posts[0].data.title.en);

				setFile(response.data.data[0].categories[0].posts[0].data.file);

				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleFile = () => {
		setLoading(true);
		const fileInput = document.querySelector("#file");
		setFile(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setFile(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الملف بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الملف، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (!title || !titleEn) {
			return toast.error("جميع البيانات مطلوبة");
		}

		setLoading(true);
		const data = {
			title: {
				ar: title,
				en: titleEn,
			},

			file,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/307`,
				{
					admin_id: auth.id,
					category_id: 42,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/productOnePage/productOneFile/show");

				toast.success("تم تحديث المقدمه بنجاح!");

				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-header"> البيان الاول</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										value={title}
										onChange={e => setTitle(e.target.value)}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										id="title"
										onChange={e => setTitleEn(e.target.value)}
										value={titleEn}
									/>
								</div>
							</div>
						</div>
						{/* ********************************************************************* */}

						{/* ********************************************************************* */}
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="firstFile">الملف الاول </label>
									<input type="file" onChange={handleFile} id="file" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default ProductOneFileShow;
