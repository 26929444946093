import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";
import { useHistory } from "react-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const HealthSectionEightShow = ({ match }) => {
	const {
		params: { informationId },
	} = match;

	const auth = useSelector(state => state.auth.user);
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [description, setDescription] = useState("");
	const [descriptionEn, setDescriptionEn] = useState("");
	const [loading, setLoading] = useState(true);
	const [picture, setPicture] = useState(userPlaceholder);
	const [pictureMeta, setPictureMeta] = useState("");

	const dispatch = useDispatch();
	const history = useHistory();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل البيانات",
				breadcrumb: "البيانات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			// .get(`${process.env.REACT_APP_API_URL}/posts/${informationId}`, {
			.get(`${process.env.REACT_APP_API_URL}/posts/heath_word_section8/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				// console.log(
				// 	response?.data?.data[0]?.categories[0]?.posts[0]?.data
				// 		.secondDescription,
				// 	"this is response"
				// );
				setTitle(response.data.data[0].categories[0].posts[0].data.title.ar);
				setTitleEn(response.data.data[0].categories[0].posts[0].data.title.en);
				setDescription(
					response.data.data[0].categories[0].posts[0].data.description.ar
				);

				setDescriptionEn(
					response.data.data[0].categories[0].posts[0].data.description.en
				);

				setPicture(response.data.data[0].categories[0].posts[0].data.image);
				setPictureMeta(
					response.data.data[0].categories[0].posts[0].data?.imageMeta
				);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = () => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (!title || !description || !titleEn || !descriptionEn) {
			return toast.error("جميع البيانات مطلوبة");
		}

		setLoading(true);
		const data = {
			title: {
				ar: title,
				en: titleEn,
			},

			description: {
				ar: description,
				en: descriptionEn,
			},

			image: picture,
			imageMeta: pictureMeta,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/322`,
				{
					admin_id: auth.id,
					category_id: 55,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/healthPage/HealthSectionEight/show");

				toast.success("تم تحديث المقدمه بنجاح!");

				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-header"> البيان الاول</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										value={title}
										onChange={e => setTitle(e.target.value)}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description">الوصف</label>
									{/* <textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setDescription(e.target.value)}
										value={description}
									/> */}
									<CKEditor
										data={description}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDescription(data);
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
										}}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										id="title"
										onChange={e => setTitleEn(e.target.value)}
										value={titleEn}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> الوصف بالانجليزيه</label>
									{/* <textarea
										id="description"
										cols="30"
										rows="2"
										onChange={e => setDescriptionEn(e.target.value)}
										value={descriptionEn}
									/> */}
									<CKEditor
										data={descriptionEn}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDescriptionEn(data);
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
										}}
									/>
								</div>
							</div>
						</div>
						{/* ********************************************************************* */}

						{/* ********************************************************************* */}
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">الصوره</label>
									<div className="form-item ">
										<input
											type="text"
											id="pictureMeta"
											onChange={e => setPictureMeta(e.target.value)}
											value={pictureMeta}
											placeholder={"ادخل وصف الصوره"}
										/>
									</div>
									<input type="file" onChange={handlePicture} id="picture" />
									<img
										src={`${process.env.REACT_APP_MAIN_URL}${picture}`}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default HealthSectionEightShow;
