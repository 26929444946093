import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "components/shared/Loader";
import userPlaceholder from "assets/images/placeholder.jpg";
import { updateMeta } from "store/actions/metaAction";
import { useHistory } from "react-router-dom";
import server from "api/server";

const WorksCeoShow = ({ match }) => {
	const {
		params: {},
	} = match;
	const myRef = useRef();

	const auth = useSelector(state => state.auth.user);
	const [meta, setMeta] = useState([]);

	const [addedMeta, setAddedMeta] = useState({
		name: "",
		content: "",
	});
	const [editedMeta, setEditedMeta] = useState({
		name: "",
		content: "",
	});
	const [disableEditedMetaInputs, setDisableEditedMetaInputs] = useState(true);

	const [loading, setLoading] = useState("");

	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "تعديل روابط السيو",
				breadcrumb: "السيو",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		try {
			setLoading(true);
			const response = await server().get("/metadataTags?type=ourworks");
			const { metadata_tags: metaDataTags } = response?.data?.data || {};
			setMeta(metaDataTags);
			setLoading(false);
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة لاحقا!");
			setLoading(false);
		}
	};

	const focusEditMetaInput = () => {
		myRef.current.focus();
		myRef.current.scrollIntoView();
	};

	const handleDeleteMetaClick = async id => {
		try {
			setLoading(true);
			await server().delete(`/metadataTags/${id}`);
			toast.success("تم بنجاح!");
			setLoading(false);
			setEditedMeta();

			onStart();
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة مره اخرى!");
			setLoading(false);
		}
	};

	const handleInputChange = e => {
		const { name, value } = e?.target || {};
		setAddedMeta({
			...addedMeta,
			[name]: value,
		});
	};
	const handleEditMetaInputChange = e => {
		const { name, value } = e?.target || {};
		setEditedMeta({
			...editedMeta,
			[name]: value,
		});
	};

	const handleAddMetaClick = async e => {
		e.preventDefault();
		const arrayOfMetaNames = meta.map(singleMeta => singleMeta?.name);
		if (arrayOfMetaNames.includes(addedMeta?.name))
			return toast.error("لايمكنك اضافه نفس العنوان مرتين");

		try {
			setLoading(true);
			await server().post(`/metadataTags`, { ...addedMeta, type: "ourworks" });
			toast.success("تم بنجاح!");
			setLoading(false);
			setAddedMeta({
				name: "",
				content: "",
			});
			onStart();
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة مره اخرى!");
			setLoading(false);
		}
	};

	const handleSaveEditMetaClick = async () => {
		const { id } = editedMeta || {};
		try {
			setLoading(true);
			await server().put(`/metadataTags/${id}`, editedMeta);
			toast.success("تم بنجاح!");
			setLoading(false);
			onStart();
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة مره اخرى!");
			setLoading(false);
		}
	};

	const handleEditMetaClick = async (e, item) => {
		e.preventDefault();
		setEditedMeta(item);
		setDisableEditedMetaInputs(false);
		focusEditMetaInput();
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={() => true}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-header">
								<h3 className="h3 ">اضافه رابط</h3>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="name">عنوان الميتا</label>
									<input
										type="text"
										id="name"
										name="name"
										value={addedMeta?.name}
										onChange={handleInputChange}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="content">وصف الميتا</label>
									<input
										type="text"
										id="content"
										name="content"
										value={addedMeta?.content}
										onChange={handleInputChange}
									/>
								</div>

								<div className="form-item mt-3 col-sm-3">
									<button
										type="button"
										onClick={handleAddMetaClick}
										className="bt bt-success btn-block"
									>
										اضافه
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-12" ref={myRef}>
						<div className="card">
							<div className="card-header">
								<h3 className="h3 ">تعديل رابط</h3>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="name">عنوان الميتا</label>
									<input
										type="text"
										id="name"
										name="name"
										value={editedMeta?.name}
										onChange={handleEditMetaInputChange}
										disabled={disableEditedMetaInputs}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="content">وصف الميتا</label>
									<input
										type="text"
										id="content"
										name="content"
										value={editedMeta?.content}
										onChange={handleEditMetaInputChange}
										disabled={disableEditedMetaInputs}
									/>
								</div>

								<div className="form-item mt-3 col-sm-3">
									<button
										type="button"
										onClick={handleSaveEditMetaClick}
										className="bt bt-success btn-block"
									>
										حفظ التعديل
									</button>
								</div>
							</div>
						</div>
					</div>

					<h3 className="h3 mt-5">الروابط المضافه</h3>
					{meta?.map(item => (
						<div className="col-md-12 my-2">
							<div className="card">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="name">عنوان الميتا</label>
										<input
											type="text"
											id="name"
											name="name"
											value={item?.name}
											readOnly
										/>
									</div>
									<div className="form-item">
										<label htmlFor="content">وصف الميتا</label>
										<input
											type="text"
											id="content"
											name="content"
											value={item?.content}
											readOnly
										/>
									</div>
									<div className="row">
										<div className="form-item mt-3 col-sm-6">
											<button
												type="button"
												onClick={e => handleEditMetaClick(e, item)}
												className="bt bt-success btn-block"
											>
												تعديل
											</button>
										</div>
										<div className="form-item mt-3 col-sm-6">
											<button
												type="button"
												onClick={() => handleDeleteMetaClick(item?.id)}
												className="bt bt-danger btn-block"
											>
												حذف
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</form>
		</section>
	);
};

export default WorksCeoShow;
