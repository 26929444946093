import axios from "axios";
import cookie from "js-cookie";

const server = () => {
	const token = cookie.get("token");

	return axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: "application/json",
		},
	});
};

export default server;
