import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { useDropzone } from "react-dropzone";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { updateMeta } from "../../store/actions/metaAction";

const WorksCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [title, setTitle] = useState("");
	const [type, setType] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [typeEn, setTypeEn] = useState("");
	const [picture, setPicture] = useState(userPlaceholder);
	const [pictureMeta, setPictureMeta] = useState("");

	const [video, setVideo] = useState(userPlaceholder);
	const dispatch = useDispatch();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		setLoading(false);
		dispatch(
			updateMeta({
				title: "اضافة عمل جديد",
				breadcrumb: "الأعمال",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const handleVideo = () => {
		const fileInput = document.querySelector("#video");
		setVideo(URL.createObjectURL(fileInput.files[0]));
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (!title || !titleEn || !type || !typeEn) {
			return toast.error("جميع البيانات مطلوبة");
		}

		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					const video = document.querySelector("#video");
					if (video.files[0]) {
						setLoading(true);
						const data = new FormData();
						data.append("file", video.files[0]);
						axios
							.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
									"Content-Type": "multipart/form-data",
								},
							})
							.then(responseVideo => {
								const data = {
									title: {
										ar: title,
										en: titleEn,
									},

									type: {
										ar: type,
										en: typeEn,
									},
									image: response.data.data.name,
									imageMeta: pictureMeta,
									video: responseVideo.data.data.name,
								};
								axios
									.post(
										`${process.env.REACT_APP_API_URL}/posts`,
										{
											category_id: 19,
											admin_id: auth.id,
											data: data,
										},
										{
											headers: {
												Authorization: `Bearer ${cookie.get("token")}`,
												Accept: "application/json",
											},
										}
									)
									.then(function (response) {
										history.push("/works/index");
										toast.success("تم اضافة العمل بنجاح!");
										setLoading(false);
									})
									.catch(function (error) {
										toast.error("خطأ، برجاء المحاولة مره اخرى!");
										setLoading(false);
									});
							});
					} else {
						const data = {
							title: {
								ar: title,
								en: titleEn,
							},

							type: {
								ar: type,
								en: typeEn,
							},
							image: response.data.data.name,
							imageMeta: pictureMeta,
						};
						axios
							.post(
								`${process.env.REACT_APP_API_URL}/posts`,
								{
									category_id: 19,
									admin_id: auth.id,
									data: data,
								},
								{
									headers: {
										Authorization: `Bearer ${cookie.get("token")}`,
										Accept: "application/json",
									},
								}
							)
							.then(function (response) {
								history.push("/works/index");
								toast.success("تم اضافة العمل بنجاح!");
								setLoading(false);
							})
							.catch(function (error) {
								toast.error("خطأ، برجاء المحاولة مره اخرى!");
								setLoading(false);
							});
					}
				})

				.catch(error => {
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان</label>
											<input
												type="text"
												id="title"
												required
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="url">نوع العمل</label>
											<input
												type="text"
												id="url"
												onChange={e => setType(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان بالانجليزيه</label>
											<input
												type="text"
												id="title"
												required
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="url">نوع العمل بالانجليزيه </label>
											<input
												type="text"
												id="url"
												onChange={e => setTypeEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">الصوره</label>
									<div className="form-item ">
										<input
											type="text"
											id="pictureMeta"
											onChange={e => setPictureMeta(e.target.value)}
											value={pictureMeta}
											placeholder={"ادخل وصف الصوره"}
										/>
									</div>
									<input type="file" onChange={handlePicture} id="picture" />
									<img src={picture} alt="avatar" className="imagePreview" />
								</div>
								<div className="form-item">
									<label htmlFor="video">الفيديو</label>
									<input type="file" onChange={handleVideo} id="video" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default WorksCreate;
