import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";

import { useHistory } from "react-router-dom";
import { data } from "jquery";

const WorkShow = ({ match }) => {
	const {
		params: { workID },
	} = match;
	const auth = useSelector(state => state.auth.user);
	const [loading, setLoading] = useState(true);
	const [title, setTitle] = useState("");
	const [type, setType] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [typeEn, setTypeEn] = useState("");

	const [picture, setPicture] = useState(userPlaceholder);
	const [pictureMeta, setPictureMeta] = useState("");

	const [video, setVideo] = useState(userPlaceholder);

	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل العمل",
				breadcrumb: "الأعمال",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${workID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setTitle(response.data.data.data.title.ar);
				setTitleEn(response.data.data.data.title.en);
				setType(response.data.data.data.type.ar);
				setTypeEn(response.data.data.data.type.en);
				setPicture(response.data.data.data.image);
				setVideo(response.data.data.data.video);
				setPictureMeta(response.data.data.data?.imageMeta);

				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleMedia = (setter, id) => {
		setLoading(true);
		const fileInput = document.querySelector(`#${id}`);
		setter(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setter(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الميديا بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الميديا، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		const data = {
			title: {
				ar: title,
				en: titleEn,
			},

			type: {
				ar: type,
				en: typeEn,
			},
			image: picture,
			imageMeta: pictureMeta,
			video,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${workID}`,
				{
					category_id: 19,
					admin_id: auth.id,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/works/index");

				toast.success("تم تحديث بيانات العمل بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان</label>
											<input
												type="text"
												name=""
												id="title"
												required
												value={title}
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="url">نوع العمل</label>
											<input
												type="text"
												id="type"
												value={type}
												onChange={e => setType(e?.target?.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان بالانجليزيه</label>
											<input
												type="text"
												id="title"
												required
												value={titleEn}
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="url"> نوع العمل بالانجليزيه</label>
											<input
												type="text"
												id="type"
												value={typeEn}
												onChange={e => setTypeEn(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">الصوره</label>
									<div className="form-item ">
										<input
											type="text"
											id="pictureMeta"
											onChange={e => setPictureMeta(e.target.value)}
											value={pictureMeta}
											placeholder={"ادخل وصف الصوره"}
										/>
									</div>
									<input
										type="file"
										onChange={() => handleMedia(setPicture, "picture")}
										id="picture"
									/>
									<img
										src={process.env.REACT_APP_MAIN_URL + picture}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
								<div className="form-item">
									<input
										type="file"
										onChange={() => handleMedia(setVideo, "video")}
										id="video"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default WorkShow;
